import { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import { useAnalyticsDispatch } from './AnalyticsContext';
import { calculateScrollTrackingStep } from './calculate-scroll-tracking-step';
export const VerticalScrollTracker = ({
  pageTitle
}) => {
  const analytics = useAnalyticsDispatch();
  const {
    y
  } = useWindowScroll();
  const [lastTracked, setLastTracked] = useState();
  useEffect(() => {
    if (pageTitle) {
      setLastTracked(undefined);
    }
  }, [pageTitle]);
  useEffect(() => {
    const step = calculateScrollTrackingStep(window.scrollY, window.document.body.offsetHeight, window.innerHeight, lastTracked);
    if (step) {
      setLastTracked(step);
      analytics({
        type: 'click-event',
        name: 'VerticalScroll',
        value1: pageTitle,
        value2: `${step}`
      });
    }
  }, [y, lastTracked, analytics, pageTitle]);
  return null;
};